/* eslint-disable */
import React, { useState } from "react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, Circle, Link as ChakraLink, Icon } from "@chakra-ui/react";
import { FiLogOut } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "utils/userSlice";

const activeRoute = (routeName) => {
  const currentPath = window.location.pathname;
  const pathParts = currentPath.split('/'); // Split the pathname into parts
  return pathParts.includes(routeName);
  //const lastPart = pathParts[pathParts.length - 1]; // Get the last part of the path
  //return lastPart === routeName; // Check if the last part matches the routeName
};

const activeSubRoute = (subRoutes) => {
  let path = window.location.pathname.split("/");
  path = path[path.length - 1];
  return subRoutes.includes(path);
};


export function SidebarLinks(props) {
  //   Chakra color mode
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState({});
  const user = useSelector((state) => state?.user?.value);
  const isLoggedIn = user.hasOwnProperty("token") ? true : false;
  const token = user.hasOwnProperty("token") ? user.token : null;
  let privileges = user.hasOwnProperty("privileges") ? user.privileges : [];
  const { routes, onCloseSideMenu } = props;
  // verifies if routeName is the one active (in browser input)

  const logOut = () => {
    dispatch(setUser({}));
    navigate("/auth/login", { replace: true });
  }

  const handleToggle = (route) => {
    const updatedToggle = {};
    for (const toggleKey in toggle) {
      if (toggle.hasOwnProperty(toggleKey)) {
        // Set the value to 'none' if the key doesn't match the passed key
        updatedToggle[toggleKey] = toggleKey !== route ? false : toggle[toggleKey];
      }
    }
    // Update the state with the updatedToggle object
    setToggle(updatedToggle);
    setToggle((prevState) => ({
      ...prevState,
      [route]: !prevState[route], // Toggle the state of the submenu
    }));
  };

  return (
    <Box>
      {routes.map((route) => (
        <>
          {!route.collapse && (
            <NavItem key={route.name} route={route} privileges={privileges} handleToggle={handleToggle} onCloseSideMenu={onCloseSideMenu}>
              {route.name}
            </NavItem>
          )}

          {route.collapse && (
            <>
              <Box onClick={() => handleToggle(route.name)} cursor={'pointer'}>
                <ParentMenu
                  key={route.name}
                  route={route}
                  privileges={privileges}
                >
                  {route.name}
                </ParentMenu>
              </Box>
              <Box
                pl="3"
                pt="1"
                pb="2"
                width="100%"
                background="brand.100"
                display={toggle[route.name] || (activeRoute(route.path.toLowerCase()) || (route.items && route.items.length > 0 && activeSubRoute(route.items))) ? "block" : "none"}
              >
                <SubMenu
                  routes={route.items}
                  path={route.path}
                  privileges={privileges}
                  onCloseSideMenu={onCloseSideMenu}
                />
              </Box>
            </>
          )}
        </>
      ))}
      
       <ChakraLink
          cursor={'pointer'}
          onClick={logOut}
          >
          <Box onClick={() => { }}
            borderRadius="5" mb="3"
            p="2">
              <HStack
              spacing={"22px"}
              py='5px'
              ps='10px'
              height="36px">
              <Flex w='100%' alignItems='center' justifyContent='center'>
                <Circle
                  background={"transparent"}
                  color={"#000000"}
                  me='18px'
                  width="30px"
                  h="30px">
                  <Icon as={FiLogOut} width="20px" height="20px" />
                </Circle>
                <Text
                  me='auto'
                  fontSize={{ sm: "md", md: "lg" }}
                  color={"#000000"}
                  fontWeight={"normal"}
                  fontFamily="ManropeLight">
                  LOGOUT
                </Text>
              </Flex>
            </HStack>
          </Box>
         </ChakraLink>
    </Box>
  )
}

const SubMenu = ({ routes, path, onCloseSideMenu, privileges }) => {
  return (
    <>
      {routes.map((route) => (
        <>
          {route.show && (
            <SubNavItem
              key={route.name}
              route={route}
              path={path}
              privileges={privileges}
              onCloseSideMenu={onCloseSideMenu}
            >
              {route.name}
            </SubNavItem>
          )}
        </>
      ))}
    </>
  );
};

const ParentMenu = ({ route, privileges, children }) => {
  const allowed = route.allow;
  const isRoleInArray = allowed.some(element => privileges.includes(element));
  return (
    <>
      {isRoleInArray && (
        <Box
          bg={(activeRoute(route.path.toLowerCase()) || (route.items && route.items.length > 0 && activeSubRoute(route.items))) ? "transparent" : "transparent"}
          borderRadius="5"
          mt="4"
          mb="4"
          p="2"
          cursor={'pointer'}
          color={(activeRoute(route.path.toLowerCase()) || (route.items && route.items.length > 0 && activeSubRoute(route.items))) ? "brand.500" : "#000000"}
        >
          {route.icon ? (
            <HStack
              spacing={(activeRoute(route.path.toLowerCase()) || (route.items && route.items.length > 0 && activeSubRoute(route.items))) ? "22px" : "22px"}
              py='5px'
              ps='10px'
              height="36px">
              <Flex w='100%' alignItems='center' justifyContent='center'>
                <Circle
                  background={(activeRoute(route.path.toLowerCase()) || (route.items && route.items.length > 0 && activeSubRoute(route.items))) ? "transparent" : "transparent"}
                  color={(activeRoute(route.path.toLowerCase()) || (route.items && route.items.length > 0 && activeSubRoute(route.items))) ? "brand.500" : "#000000"}
                  me='18px'
                  width="30px"
                  h="30px">
                  {route.icon}
                </Circle>
                <Text
                  me='auto'
                  fontSize={{ sm: "md", md: "lg" }}
                  color={(activeRoute(route.path.toLowerCase()) || (route.items && route.items.length > 0 && activeSubRoute(route.items))) ? "brand.500" : "#000000"}
                  fontWeight={
                    (activeRoute(route.path.toLowerCase()) || (route.items && route.items.length > 0 && activeSubRoute(route.items)))
                      ? "normal"
                      : "normal"
                  }
                  fontFamily="ManropeLight">
                  {route.name}
                </Text>
              </Flex>
            </HStack>
          ) : (
            <HStack
              spacing={
                (activeRoute(route.path.toLowerCase()) || (route.items && route.items.length > 0 && activeSubRoute(route.items))) ? "22px" : "22px"
              }
              py='5px'
              ps='10px'
              w='100%'>
              <Text
                me='auto'
                fontSize={{ sm: "md", md: "lg" }}
                color={(activeRoute(route.path.toLowerCase()) || (route.items && route.items.length > 0 && activeSubRoute(route.items))) ? "brand.500" : "#000000"}
                fontWeight={
                  (activeRoute(route.path.toLowerCase()) || (route.items && route.items.length > 0 && activeSubRoute(route.items)))
                    ? "bold"
                    : "normal"
                }>
                {route.name}
              </Text>
              <Box h='36px' w='4px' bg='transparent' borderRadius='5px' />
            </HStack>
          )}
        </Box>
      )}
    </>
  );
};

const SubNavItem = ({ route, path, privileges, onCloseSideMenu, children }) => {
  const allowed = route.allow;
  const isRoleInArray = allowed.some(element => privileges.includes(element));
  return (
    <>
      {isRoleInArray && (
        <ChakraLink
          as={ReactRouterLink}
          to={route.layout + "/" + path + "/" + route.path}
          onClick={onCloseSideMenu}
        >
          <Box
            pl="2"
            mt="2"
            mb="2"
            fontSize="17"
            color="#ffffff"
            textAlign="center"
            borderRightWidth={(activeRoute(route.path.toLowerCase()) || (route.subRoutes && route.subRoutes.length > 0 && activeSubRoute(route.subRoutes))) ? "3px" : "0px"}
            borderRightColor={(activeRoute(route.path.toLowerCase()) || (route.subRoutes && route.subRoutes.length > 0 && activeSubRoute(route.subRoutes))) ? "brand.700" : "none"}
          >
            {route.icon ? (
              <HStack
                spacing={
                  activeRoute(route.path.toLowerCase()) ? "22px" : "22px"
                }
                py='5px'
                ps='10px'
                w='100%'
                h='36px'>
                <Flex w='100%' alignItems='center' justifyContent='center'>
                  <Circle
                    color={activeRoute(route.path.toLowerCase()) ? "brand.500" : "#000000"}
                    me='18px'
                    background={activeRoute(route.path.toLowerCase()) ? "transparent" : "transparent"}
                    width="30px"
                    h="30px"
                    fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}>
                    {route.icon}
                  </Circle>
                  <Text
                    me='auto'
                    fontFamily="ManropeRegular"
                    fontSize={{ sm: "md", md: "lg" }}
                    color={activeRoute(route.path.toLowerCase()) ? "brand.500" : "#000000"}
                    fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}>
                    {route.name}
                  </Text>
                </Flex>
                <Box
                  h='36px'
                  w='4px'
                  bg={activeRoute(route.path.toLowerCase()) ? "transparent" : "transparent"}
                  borderRadius='5px'
                />
              </HStack>
            ) : (
              <HStack
                spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
                py='5px'
                ps='10px'
                w='100%'
                h='36px'>
                <Text
                  fontFamily="ManropeRegular"
                  fontSize={{ sm: "md", md: "lg" }}
                  me='auto'
                  color={activeRoute(route.path.toLowerCase()) ? "brand.500" : "#000000"}
                  fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}>
                  {route.name}
                </Text>
                <Box h='36px' w='4px' bg='transparent' borderRadius='5px' />
              </HStack>
            )}
          </Box>
        </ChakraLink>
      )}
    </>
  );
};

const NavItem = ({ route, privileges, handleToggle, onCloseSideMenu, children }) => {
  const allowed = route.allow;
  const isRoleInArray = allowed.some(element => privileges.includes(element));
  return (
    <>
      {isRoleInArray && (
        <ChakraLink
          onClick={onCloseSideMenu}
          as={ReactRouterLink}
          to={route.layout + "/" + route.path}
          cursor={'pointer'}>
          <Box
            onClick={() => handleToggle(route.name)}
            height="60px"
            bg={(activeRoute(route.path.toLowerCase()) || (route.subRoutes && route.subRoutes.length > 0 && activeSubRoute(route.subRoutes))) ? "brand.100" : "transparent"}
            borderRightWidth={(activeRoute(route.path.toLowerCase()) || (route.subRoutes && route.subRoutes.length > 0 && activeSubRoute(route.subRoutes))) ? "3px" : "0px"}
            borderRightColor={(activeRoute(route.path.toLowerCase()) || (route.subRoutes && route.subRoutes.length > 0 && activeSubRoute(route.subRoutes))) ? "brand.700" : "none"}
            mb="3"
            p="2"
          >
            {route.icon ? (
              <Box>
                <HStack
                  spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "22px"}
                  py='5px'
                  ps='10px'>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Circle
                      color={activeRoute(route.path.toLowerCase()) ? "brand.700" : "#000000"}
                      me='18px'
                      width="30px"
                      h="30px">
                      {route.icon}
                    </Circle>
                    <Text
                      me='auto'
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? "brand.700" : "#000000"
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold" : "normal"
                      }
                      fontFamily="ManropeLight">
                      {route.name}
                    </Text>
                  </Flex>
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "22px"
                  }
                  py='5px'
                  ps='10px'>
                  <Text
                    me='auto'
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? "#ffffff"
                        : "#000000"
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }>
                    {route.name}
                  </Text>
                  <Box h='36px' w='4px' bg='transparent' borderRadius='5px' />
                </HStack>
              </Box>
            )}
            {route.collapse ? <Box ml="50px">{(createLinks(route.items))}</Box> : ""}
          </Box>
        </ChakraLink>
      )}
    </>
  );
};


export default SidebarLinks;
