import React from "react";
// Chakra imports
import {
  Box,
  HStack,
  Container,
  Image,
  VStack,
  Heading,
  Flex,
  Text,
  Center
} from "@chakra-ui/react";
import PropTypes from "prop-types";

function AuthIllustration(props) {
  const { children, illustrationImage, heading } = props;
  return (
    <Container
      width="100%"
      maxW="100%"
      background="#fff"
      padding="0px"
      h={{
        sm: "initial",
        md: "unset",
        lg: "100vh",
        xl: "100vh",
      }}
    >
      <HStack w="100%">
        <Box
          p="0"
          width="40%"
          h="100%"
          minH="100vh"
          background="brand.500"
          display={{sm: "none", md: "block" }}
        >
          <Center width="100%" height="100%">
            <VStack
              boxSize="sm"
              width="100%"
              spacing="8"
              mt="20vh"
              mx="auto"
            >
              <Box width="100%">
                <Heading
                  mt="5"
                  size={{ sm: "md", md: "lg", lg:"xl" }}
                  textAlign={"center"}
                  color={"#ffffff"}
                  fontFamily="ManropeSemiBold"
                  fontWeight="bold"
                >
                  {heading}
                </Heading>
              </Box>
              <Box display="flex"
                alignItems="center"
                justifyContent="center">
                <Image src={illustrationImage} width="50%" height="auto" />
              </Box>
            </VStack>
          </Center>
        </Box>

        <Box width={{sm: "100%", md: "60%" }} zIndex={0}>
          {children}
           <Box width="100%" position="fixed" bottom="0" bg="white" p="2">
             <Flex gap="4">
              <Text color="brand.500"><a href="https://akowe.app/terms">Terms & Conditions</a></Text>
              <Text color="brand.500"><a href="https://akowe.app/privacy">Privacy Policy</a></Text>
              </Flex>
            </Box>
        </Box>
      </HStack>
    </Container>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
