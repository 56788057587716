import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Text,
  Select,
  Button,
  Heading,
  Stack,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Skeleton,
  SkeletonText,
  FormControl,
  FormLabel,
  Input,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { checkprivileges } from "helpers/utils";
import Paginate from "components/navigation/Paginate.js";
import Card from "components/card/Card.js";
import {
  GetUploadBatches,
  UploadCertificate,
  DeleteBatch
} from "services/certificateService";
import { GetInstitution } from "services/institutionService";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiArrowLeft } from "react-icons/fi";
import { FaUpload } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
const { DateTime } = require("luxon");

const formatTime = (timestamp) => {
  const myDateTime = DateTime.fromISO(timestamp);
  return myDateTime.toLocaleString(DateTime.DATETIME_MED);
};

export default function Index() {
  const toast = useToast();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];

  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(200);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [batches, setBatches] = useState([]);
  const [institutions, setIntitutions] = useState([]);
  const [batchId, setBatchId] = useState("");
  const [uploadData, setUploadData] = useState({
    institution: "",
    file: "",
    zip: "",
    cover: "",
    year: ""
  });

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1999 }, (_, i) => i + 2000);

  const handleUploadCertificates = async () => {
    try {
      setUploadLoading(true);
      const response = await UploadCertificate(uploadData, token);
      setUploadLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        getUploadBatches();
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setUploadLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadData((prev) => ({ ...prev, file }));
  };

  const handleZipChange = (event) => {
    const zip = event.target.files[0];
    setUploadData((prev) => ({ ...prev, zip }));
  };

  const handleCoverChange = (event) => {
    const cover = event.target.files[0];
    setUploadData((prev) => ({ ...prev, cover }));
  };

  const getUploadBatches = async () => {
    try {
      setLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const filter = {
        deleted: false,
      };

      const response = await GetUploadBatches(pagination, filter, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setBatches(response?.data?.logs);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const deleteBatch = async () => {
    try {
      setLoading(true);
      const response = await DeleteBatch(batchId, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "Upload batch deleted successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUploadBatches();
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getInstitutions = async () => {
    try {
      setLoading(true);
      const response = await GetInstitution(token);
      setLoading(false);
      if (response.success) {
        setIntitutions(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    !checkprivileges(privileges, ["Manage Certificates", "Admin"]) && (toast({ title: "You are not allowed", status: "error", duration: 9000, position: "top-right", isClosable: true, }) && navigate("/admin/dashboard"));
    getUploadBatches();
    getInstitutions();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon
              as={FiArrowLeft}
              onClick={onClose}
              width="20px"
              height="20px"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid>
              <Box textAlign="center">
                <Heading
                  fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}
                >
                  Are you sure you want to delete this?
                </Heading>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              No
            </Button>
            <Button
              variant="primary"
              background="#000000"
              isLoading={loading}
              loadingText="Please Wait"
              onClick={(e) => {
                e.preventDefault();
                deleteBatch();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Box mt={{ sm: "40px", md: "100px" }} mb="20">
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          w="100%"
          p="2"
          spacing="2"
          mb="4"
        >
          <Box mb="5">
            {" "}
            <Text
              color={"black"}
              mb="4px"
              fontFamily={"ManropeSemiBold"}
              fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
            > Upload Academic Records
            </Text>
          </Box>
          <Box textAlign="right">
          </Box>
        </SimpleGrid>


        <Card bg="gray.200">
          <SimpleGrid
            width="100%"
            columns={{ base: 1, md: 3 }}
            spacing="6"
            mb="15"
            mt="5">
            <Box>
              <FormControl isRequired>
                <FormLabel fontWeight="bold">School/Institution</FormLabel>
                <Select
                  bg="white"
                  variant="main"
                  placeholder="Select School/Instituition"
                  value={uploadData.institution}
                  onChange={(e) => {
                    setUploadData(prevUploadData => ({
                      ...prevUploadData,
                      institution: e.target.value
                    }));
                  }}
                >
                  {
                    institutions.length > 0 &&
                    institutions.map((institution) => (
                      <option key={institution._id} value={institution._id}>
                        {institution.institutionName}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl isRequired>
                <FormLabel fontWeight="bold">Year</FormLabel>
                <Select
                  bg="white"
                  variant="main"
                  placeholder="Select Year"
                  value={uploadData.year}
                  onChange={(e) => {
                    setUploadData(prevUploadData => ({
                      ...prevUploadData,
                      year: e.target.value
                    }));
                  }}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormLabel fontWeight="bold">Records Sheet (.xlxs)</FormLabel>
              <Input height="50px" borderRadius="15" pt="2" borderWidth="1px" background="#ffffff" borderColor="#999999"
                type="file"
                onChange={handleFileChange}
              />
            </Box>
            <Box>
              <FormLabel fontWeight="bold">Year Book Images (.zip)</FormLabel>
              <Input height="50px" borderRadius="15" pt="2" borderWidth="1px" background="#ffffff" borderColor="#999999"
                type="file"
                onChange={handleZipChange}
              />
            </Box>
            <Box>
              <FormLabel fontWeight="bold">Cover Image</FormLabel>
              <Input height="50px" borderRadius="15" pt="2" borderWidth="1px" background="#ffffff" borderColor="#999999"
                type="file"
                onChange={handleCoverChange}
              />
            </Box>
            <Box>
              <FormLabel fontWeight="bold">&nbsp;</FormLabel>
              <Button
                variant="brand"
                width="140px"
                isLoading={uploadLoading}
                loadingText={"Please Wait"}
                onClick={handleUploadCertificates}>
                <Icon as={FaUpload} width="16px" height="16px" />&nbsp;&nbsp;Upload
              </Button>
            </Box>
          </SimpleGrid>
        </Card>


        {loading ? (
          <LoadingCertificates />
        ) : (
          <TableContainer mt="10">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th color="#000000">Batch ID</Th>
                  <Th color="#000000">Institution Name</Th>
                  <Th color="#000000">Year</Th>
                  <Th color="#000000">Upload Count</Th>
                  <Th color="#000000">Upload Date</Th>
                  <Th color="#000000">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {batches &&
                  batches.map((batch) => (
                    <Tr>
                      <Td>{batch._id}</Td>
                      <Td>{batch?.institution?.institutionName}</Td>
                      <Td>{batch?.year}</Td>
                      <Td>{batch.uploadCount}</Td>
                      <Td>{formatTime(batch.createdAt)}</Td>
                      <Td>
                        {" "}
                        <Menu>
                          <MenuButton
                            as={Button}
                            variant="brand"
                            width="100px"
                            rightIcon={<ChevronDownIcon />}
                          >
                            Action
                          </MenuButton>
                          <MenuList>

                            <MenuItem
                              onClick={() => {
                                setBatchId(batch._id);
                                onOpen();
                              }}
                            >
                              Delete &nbsp;{" "}
                              <Icon
                                as={MdDelete}
                                width="20px"
                                height="20px"
                                color="black"
                              />{" "}
                            </MenuItem>

                            {privileges.includes("Admin") && <>

                              <MenuItem
                                onClick={() => {
                                  setBatchId(batch._id);
                                  onOpen();
                                }}
                              >
                                Delete &nbsp;{" "}
                                <Icon
                                  as={MdDelete}
                                  width="20px"
                                  height="20px"
                                  color="black"
                                />{" "}
                              </MenuItem> </>
                            }
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {pageCount > 0 && (
          <Box width="100%" mt="5">
            <Paginate
              pageCount={pageCount}
              setFrom={setFrom}
              getRecords={getUploadBatches}
            />
          </Box>
        )}
      </Box>
    </>
  );
};


const LoadingCertificates = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};