import React from "react";
import { Icon } from "@chakra-ui/react";
import { FiUsers, FiUser, FiHome, FiUserPlus, FiCreditCard } from "react-icons/fi";
import { FaCog, FaKey, FaBriefcase, FaPlus, FaInfoCircle, FaHeadphonesAlt, FaHistory } from "react-icons/fa";

import Dashboard from "views/app/dashboard";
import Search from "views/app/search";
import History from "views/app/history";
import Payment from "views/app/payment";

import SettingsLayout from "views/app/settings/layout";
import Profile from "views/app/settings/index";
import Roles from "views/app/settings/roles";
import AddRole from "views/app/settings/addrole";
import ChangePassword from "views/app/settings/changepassword";
import OrganizationProfile from "views/app/settings/organization";
import Status from "views/app/settings/status";
import Support from "views/app/settings/support";

import AccountLayout from "views/app/user/layout";
import Users from "views/app/user/users";
import AddUser from "views/app/user/adduser";

const routes = [
  {
    name: "DASHBOARD",
    nameAbbrv: "Dashboard",
    layout: "/app",
    path: "dashboard",
    icon: <Icon as={FiHome} width="20px" height="20px" />,
    component: <Dashboard />,
    collapse: false,
    allow: ["Organization"],
  },
  /*{
    name: "SEARCH CREDENTIALS",
    nameAbbrv: "Search Credential",
    layout: "/app",
    path: "search",
    icon: <Icon as={FiSearch} width="20px" height="20px" />,
    component: <Search />,
    collapse: false,
    allow: ["Organization"],
  },*/
  {
    name: "SEARCH HISTORY",
    nameAbbrv: "Search History",
    layout: "/app",
    path: "history",
    icon: <Icon as={FaHistory} width="20px" height="20px" />,
    component: <History />,
    collapse: false,
    allow: ["Organization"],
  },
  {
    name: "MY PAYMENTS",
    nameAbbrv: "My Payments",
    layout: "/app",
    path: "payments",
    icon: <Icon as={FiCreditCard} width="20px" height="20px" />,
    component: <Payment />,
    collapse: false,
    allow: ["Organization"],
  },
  {
    name: "USERS",
    nameAbbrv: "Users",
    layout: "/app",
    path: "users",
    icon: <Icon as={FiUsers} width="20px" height="20px" />,
    component: <AccountLayout />,
    collapse: true,
    show: true,
    allow: ["Organization"],
    items: [
      {
        name: "USERS",
        layout: "/app",
        path: "organizationusers",
        icon: (
          <Icon as={FiUsers} width="20px" height="20px" />
        ),
        component: <Users />,
        collapse: false,
        show: true,
        allow: ["Organization"],
      },
      {
        name: "ADD USER",
        layout: "/app",
        path: "adduser",
        icon: (
          <Icon as={FaPlus} width="20px" height="20px" />
        ),
        component: <AddUser />,
        collapse: false,
        show: true,
        allow: ["Organization"],
      },
      {
        name: "ADD USER",
        layout: "/app",
        path: "adduser/:userId",
        icon: (
          <Icon as={FiUserPlus} width="20px" height="20px" />
        ),
        component: <AddUser />,
        collapse: false,
        show: false,
        allow: ["Organization"],
      },
    ]
  },
  {
    name: "STATUS",
    nameAbbrv: "Status",
    layout: "/app",
    path: "status",
    icon: <Icon as={FaInfoCircle} width="20px" height="20px" />,
    component: <Status />,
    collapse: false,
    allow: ["Organization"],
  },
  {
    name: "SETTINGS",
    nameAbbrv: "Settings",
    layout: "/app",
    path: "settings",
    icon: <Icon as={FaCog} width="20px" height="20px" />,
    component: <SettingsLayout />,
    collapse: true,
    show: true,
    allow: ["Organization"],
    items: [
      /*{
        name: "USER ROLES",
        layout: "/app",
        path: "roles",
        icon: (
          <Icon as={FaUserShield} width="20px" height="20px" />
        ),
        component: <Roles />,
        collapse: false,
        show: true,
        allow: ["Admin", "Organization", "Manage Roles"],
      },
      {
        name: "ADD ROLE",
        layout: "/app",
        path: "addrole",
        icon: (
          <Icon as={FaUserShield} width="20px" height="20px" />
        ),
        component: <AddRole />,
        collapse: false,
        show: false,
        allow: ["Admin", "Organization", "Manage Roles"],
      },
      {
        name: "ADD ROLE",
        layout: "/app",
        path: "addrole/:roleId",
        icon: (
          <Icon as={FaUserShield} width="20px" height="20px" />
        ),
        component: <AddRole />,
        collapse: false,
        show: false,
        allow: ["Admin", "Organization", "Manage Roles"],
      },*/
      {
        name: "PROFILE",
        layout: "/app",
        path: "index",
        icon: (
          <Icon as={FiUser} width="20px" height="20px" />
        ),
        component: <Profile />,
        collapse: false,
        show: true,
        allow: ["Organization"],
      },
      {
        name: "ORGANIZATION",
        layout: "/app",
        path: "organization",
        icon: (<Icon as={FaBriefcase} width="20px" height="20px" />),
        component: <OrganizationProfile />,
        collapse: false,
        show: true,
        allow: ["Organization"],
      },
      {
        name: "CHANGE PASSWORD",
        layout: "/app",
        path: "changepassword",
        icon: (
          <Icon as={FaKey} width="20px" height="20px" />
        ),
        component: <ChangePassword />,
        collapse: false,
        show: true,
        allow: ["Organization"],
      }
    ],
  },
  {
    name: "CUSTOMER CARE",
    nameAbbrv: "Customer Care",
    layout: "/app",
    path: "support",
    icon: <Icon as={FaHeadphonesAlt} width="20px" height="20px" />,
    component: <Support />,
    collapse: false,
    allow: ["Organization"],
  }
];

export default routes;


export const smallRoutes = [
  {
    name: "DASHBOARD",
    nameAbbrv: "Dashboard",
    layout: "/app",
    path: "dashboard",
    icon: <Icon as={FiHome} width="20px" height="20px" />,
    component: <Dashboard />,
    collapse: false,
    allow: ["Organization"],
  },
  {
    name: "SEARCH HISTORY",
    nameAbbrv: "Search History",
    layout: "/app",
    path: "history",
    icon: <Icon as={FaHistory} width="20px" height="20px" />,
    component: <History />,
    collapse: false,
    allow: ["Organization"],
  },{
    name: "MY PAYMENTS",
    nameAbbrv: "My Payments",
    layout: "/app",
    path: "payments",
    icon: <Icon as={FiCreditCard} width="20px" height="20px" />,
    component: <Payment />,
    collapse: false,
    allow: ["Organization"],
  }, 
  {
    name: "SETTINGS",
    nameAbbrv: "Settings",
    layout: "/app",
    path: "settings",
    icon: <Icon as={FaCog} width="20px" height="20px" />,
    component: <SettingsLayout />,
    collapse: true,
    show: true,
    allow: ["Organization"]
  }
]
